<mat-card>
  <div *ngIf="isLogin">
    <h3>Sign In</h3>
  </div>

  <div *ngIf="isPasswordReset">
    <h3>Reset Password</h3>
    <button size="small" mat-button (click)="changeType('login')">Back</button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field [color]="email.valid && 'accent'">
      <input
        matInput
        formControlName="email"
        type="email"
        placeholder="Email"
        autocomplete="off"
      />

      <mat-error *ngIf="email.invalid && email.dirty">
        You must enter a valid email address
      </mat-error>
    </mat-form-field>

    <mat-form-field [color]="email.valid && 'accent'" *ngIf="!isPasswordReset">
      <input
        matInput
        formControlName="password"
        type="password"
        placeholder="Password"
        autocomplete="off"
      />

      <mat-error *ngIf="password.invalid && password.dirty">
        Password must be at least 6 characters long
      </mat-error>
    </mat-form-field>

    <mat-error class="server-error">{{ serverMessage }}</mat-error>

    <button
      *ngIf="isPasswordReset"
      mat-stroked-button
      type="submit"
      [disabled]="loading"
    >
      Send Reset Email
    </button>

    <button
      *ngIf="!isPasswordReset"
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="form.invalid || loading"
    >
      Submit
    </button>
  </form>

  <button
    mat-button
    *ngIf="isLogin && !isPasswordReset"
    (click)="changeType('reset')"
  >
    Forgot password?
  </button>
</mat-card>
