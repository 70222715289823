<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="100%" fxFlex.sm="60%" fxFlex.xs="90%">
    <div *ngIf="isAdmin()">
      <button mat-icon-button (click)="back()">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
    </div>
    <!-- HEADER -->
    <div *ngIf="project$ | async as project">
      <div
        class="padding-top"
        ngClass.lt-md="width-mobile"
        ngClass.lt-xl="width-desktop"
        ngClass.xl="width-xl"
      >
        <h1>{{ project.title }}</h1>
        <p class="description">
          {{ project.description }}
        </p>
        <div *ngIf="project.button?.text" class="padding-top">
          <button
            id="button"
            mat-stroked-button
            (click)="open(project.button.url)"
          >
            {{ project.button.text }}
          </button>
        </div>
        <br />
        <span> {{ project.caption }}</span>
      </div>
      <!-- IMAGES -->
      <div *ngFor="let image of images$ | async">
        <p
          ngClass.lt-md="width-mobile"
          ngClass.lt-xl="width-desktop"
          ngClass.xl="width-xl"
          class="legende-projets"
          *ngIf="image.caption"
        >
          {{ image.caption }}
        </p>

        <div *ngIf="image.video?.isVideo">
          <video
            class="padding-top"
            ngClass.lt-md="width-mobile"
            ngClass.lt-xl="width-desktop"
            ngClass.xl="width-xl"
            src="{{ image.downloadUrl }}"
            controls
            type="video/mp4"
            poster="{{ image.video.poster }}"
          >
            <p>
              Désolé, votre navigateur ne prend pas en charge les vidéos HTML5.
            </p>
          </video>
        </div>

        <div *ngIf="!!!image.video">
          <img
            class="padding-top"
            ngClass.lt-md="width-mobile"
            ngClass.lt-xl="width-desktop"
            ngClass.xl="width-xl"
            src="{{ image.downloadUrl }}"
          />
        </div>
      </div>

      <p
        ngClass.lt-md="width-mobile"
        ngClass.lt-xl="width-desktop"
        ngClass.xl="width-xl"
        class="legende-projets"
      >
        {{ project.finalCaption }}
      </p>
    </div>
  </div>
</div>
