<div fxLayout="row" fxLayoutAlign="center center">
  <div fxFlex="nogrow" fxFlex.lt-md="100">
    <!-- TOOLBAR -->
    <app-toolbar></app-toolbar>
    <div ngClass.gt-sm="app-container">
      <!-- MENU -->
      <div class="menu-container" fxHide.lt-md="true">
        <a routerLink="home">
          <img id="logo" ngClass.xs="big" src="assets/img/logoweb.png" />
        </a>
        <app-menu></app-menu>
      </div>

      <!-- PAGE -->
      <div ngClass.gt-sm="content-container-desktop">
        <router-outlet></router-outlet>
        <div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
