<div fxFlexFill fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
  <div fxFlex.lt-md="90">

    <button mat-icon-button (click)="back()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <h2 class="title">Liste des catégories</h2>
    <p class="subtitle">
      Pour ajouter une nouvelle catégorie, uploade une image-titre ci-dessous:
    </p>

    <div class="upload-box"><app-uploader></app-uploader></div>

    <p class="subtitle">
      Tu peux ensuite réarranger leur ordre ici:
    </p>
    <div
      cdkDropList
      [cdkDropListData]="categories$ | async"
      class="list"
      (cdkDropListDropped)="drop($event)"
    >
      <div *ngFor="let category of categories$ | async" class="item" cdkDrag>
        {{ category.name }}
        <img
          ngClass.xs="title-img-xs"
          ngClass.gt-xs="title-img"
          src="{{ category.downloadUrl }}"
        />
        <button
          mat-icon-button
          (click)="deleteCategory(category)"
          [disabled]="!isEmpty(category)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
