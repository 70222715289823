<div
  ngClass.lt-md="container-sm"
  ngClass.gt-sm="container"
>
  <img class="img-title" src="assets/img/bonjour.jpg" width="150" />
  <p class="text">
    Je suis Léna, illustratrice free-lance.
    <br />
    Basée à Lyon, je serais ravie de travailler
    <br />
    avec vous partout dans le monde :)
    <br />
    N'hésitez pas à
    <a href="mailto:atelier@lenapiroux.com" style="color: #000000;"
      >me contacter</a
    >.
    <br />
    <br />
  </p>

  <img class="img-title" src="assets/img/hi.jpg" width="50" />
  <p class="text">
    I'm Lena, a free-lance illustrator.
    <br />
    Based in Lyon, I would love to work with you,
    <br />
    no matter where you are in this world :)
    <br />
    Don't hesitate to
    <a href="mailto:atelier@lenapiroux.com" style="color: #000000;"
      >contact me</a
    >.
  </p>
  <br />
  <br />
  <br />
  <img class="img-title" src="assets/img/coucou.jpg" width="500"/>
</div>
