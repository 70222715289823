<div class="container" ngClass.xl="margin-right">
  <div *ngFor="let category of categories$ | async; let i = index">
    <!-- IMAGE TITLE  -->
    <img
      ngClass.xs="title-img-xs"
      ngClass.gt-xs="title-img"
      [class.first-img]="i === 0"
      [class.other-img]="i > 0"
      src="{{ category.downloadUrl }}"
    />
    <!-- PROJECTS -->
    <ul *ngFor="let projectId of sortByPosition(category.projectIds)">
      <div
        ngClass.gt-xs="project"
        ngClass.sm="project-sm"
        ngClass.xs="project-xs"
      >
        <a
          class="project-link"
          routerLink="/{{
            getProjectById(projectId.id)
              ?.title.replace(' ', '-')
              .replace('/', '&')
          }}"
        >
          <p ngClass.xs="project-title-xs" ngClass.sm="project-title-sm">
            {{ getProjectById(projectId.id)?.title }}
          </p>
        </a>
      </div>
    </ul>
  </div>
  <img
    ngClass.xs="title-img-xs"
    ngClass.gt-xs="title-img"
    class="contact-title"
    src="assets/img/menu-contact.png"
  />
  <ul>
    <div ngClass.gt-xs="project" ngClass.xs="project-xs">
      <a class="project-link" routerLink="contact">
        <p ngClass.xs="project-title-xs" ngClass.sm="project-title-sm">
          Bonjour !
        </p>
      </a>
    </div>
  </ul>
  <div class="img-buttons">
    <a href="http://instagram.com/lenapiroux" target="_blank">
      <img
        class="contact-img"
        src="assets/img/instalena.png"
        alt="Contactez-moi!"
      />
    </a>
    <a href="https://www.etsy.com/fr/shop/atelierlenapiroux" target="_blank">
      <img class="contact-img" src="assets/img/etsy.png" alt="Contactez-moi!" />
    </a>
    <a href="mailto:atelier@lenapiroux.com">
      <img class="contact-img" src="assets/img/mail.png" alt="Contactez-moi!" />
    </a>
  </div>
</div>
