<div
  ngClass.xs="container-xs"
  ngClass.sm="container-sm"
  ngClass.gt-sm="container"
  ngClass.xl="container-xl"
>
  <mat-grid-list [cols]="grid.cols" [gutterSize]="grid.gutterSize">
    <mat-grid-tile *ngFor="let vignette of vignettes">
      <img
        routerLink="/{{ getProjectById(vignette.projectId)?.title.replaceAll(' ', '-').replaceAll('/', '&') }}"
        class="tiles pointer"
        src="{{ vignette.downloadUrl }}"
      />
    </mat-grid-tile>
  </mat-grid-list>
</div>
