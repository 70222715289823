<div fxFlexFill fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
  <div fxFlex="95">

    <button mat-icon-button (click)="back()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <h2 class="title">Vignettes</h2>
    <p class="subtitle">
      Pour ajouter une nouvelle vignette, uploade une image ci-dessous:
    </p>
    <div class="upload-box">
      <app-uploader></app-uploader>
    </div>

    <p class="subtitle">
      Après avoir uploadé des images, rafraîchis la page.
      <br />
      Tu peux ensuite changer l'ordre des vignettes en les déplaçant.
    </p>
    <app-dragable-grid></app-dragable-grid>

    <h2 class="title">Liens des vignettes</h2>
    <p class="subtitle">
      Choisis le projet que tu veux associer à chaque vignette.
      <br />
      Tu peux cliquer sur la vignette en question pour vérifier le lien.
    </p>
    <div *ngFor="let vignette of vignettes$ | async">
      <h3>Vignette {{ vignette.position + 1 }}</h3>
      <div fxLayout="row" fxLayoutAlign="center center">
        <img
          fxFlex="nogrow"
          class="tiles"
          (click)="navigateProject(vignette.projectId)"
          src="{{ vignette.downloadUrl }}"
        />

        <app-vignette-form fxFlex [vignette]="vignette"></app-vignette-form>
      </div>
    </div>
  </div>
</div>
